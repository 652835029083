<template>
  <div class="WordSection1">
    <p class="MsoNormal" style="font-size: 12pt; line-height: 1.6">
      {{ JSON.stringify(project) }}
      <span style="line-height: 115%">1. </span
      ><b
        ><span style="line-height: 115%"
          >THIS CONFIDENTIALITY AND NON - DISCLOSURE AGREEMENT (“this
          Agreement”)
        </span></b
      ><span style="line-height: 115%">is made </span
      ><b><span style="line-height: 115%">on </span></b
      ><span style="line-height: 115%"
        >this day,
        <b>
          {{ new Date().getDate() }} day of
          {{ monthName(new Date().getMonth()) }}
          , {{ new Date().getFullYear() }} as consent to use the Grupa platform
        </b></span
      ><span style="line-height: 115%"
        >between <b>PLATOHUB INC dba Grupa, </b>a corporation registered under
        the laws of the State of Delaware and with registered office address at
        <b>4320 Stevens Creek Blvd, San Jose, CA</b>; </span
      ><span style="line-height: 115%"
        >(hereinafter referred to as “<b>Grupa</b>” which expression shall where
        the context so admits include its successors and assigns)</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <span style="line-height: 115%">AND</span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <span style="">
        <b>
          {{ teamDetails.team.team_name ? teamDetails.team.team_name : "" }} </b
        >an expert hereby known as the “<b>CONSULTANT</b>” and collectively
        known as “Parties”.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <span style="line-height: 115%">WHEREAS:</span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span style="line-height: 115%"
        >●<span style="">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="line-height: 115%"
        >This Agreement is created for the purpose of preventing the
        unauthorized disclosure of confidential and proprietary information
        regarding the development of <b>the Project</b>, hereinafter known as
        the “Software”.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0.05in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span style="line-height: 115%">●<span>&nbsp;&nbsp;&nbsp; </span></span
      ><span style="line-height: 115%"
        >Grupa shall on behalf of third parties, its <b>“Product Partners”</b>,
        on its platform have sole ownership of the Software and the Consultant
        shall be prohibited from disclosing confidential and proprietary
        information that is to be released by the Grupa to develop the
        Software.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0.08in;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span style="line-height: 115%">●<span>&nbsp;&nbsp;&nbsp; </span></span
      ><span style="line-height: 115%"
        >The Parties acknowledge that any Confidential Information exchanged
        between them should be kept confidential and have therefore agreed to
        enter into this Agreement.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <span style="line-height: 115%">NOW IT IS HEREBY AGREED AS FOLLOWS:</span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <span style="line-height: 115%; color: #00a9e0">1. DEFINITIONS</span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span style="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
      <span style="line-height: 115%"
        >1.1  “Agreement” means this Confidentiality and Non-Disclosure
        Agreement between the Parties;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >1.2  “Confidential Information” means all information or data disclosed
        (whether in writing, orally or by any other means) to one party by the
        other party or by a third party on behalf of the other<br />
        party and shall include but not be limited to;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >(a)  Information relating to the organizational structure, marketing
        strategy and valuation of the<br />
        Parties and any other information relating to the business operations,
        processes, plans, intentions, product information, know-how, design
        rights, trade secrets, software, market opportunities, customers and
        affairs of the Parties;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >(b)  technical information to any products or services supplied by the
        Parties;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >(c)  information relating to the officers, directors or employees of
        the Parties including their<br />
        terms and conditions of employment or engagement;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >(d)  the information which is disclosed orally and identified as
        confidential at the time of<br />
        disclosure;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >(e)  The existence and content of this Agreement and matters incidental
        thereto;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >(f)  any information ascertainable by the inspection of accounts, and
        other records;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >(g)  financial, legal and any other information provided in due
        diligence checklists to be provided<br />
        after execution hereof;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >(h)  any information relating to that Party’s business, operations,
        plans, intentions, market<br />
        opportunities, customers and business affairs; and</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 1in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%">(i)  any Intellectual Property.</span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <span style="line-height: 115%"
        >But shall exclude any part of such disclosed information or data and
        information supplied by Grupa Product Owners, Partners, and Stakeholders
        of the project which:</span
      >
    </p>
    <br />
    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <b><span style="line-height: 115%">Alexander Rushing</span></b>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
        display: none;
      "
    >
      <span style="line-height: 115%"
        >Document Ref: DBJ4S-LVBWS-KNMN8-QPEHA</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span style="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >(a)  is or becomes common or public knowledge in any way without breach
        of this Agreement by the receiving party; or</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span style="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >(b)  the Consultant can show; (i) was in its possession or known to it
        by being in its use or being recorded in its files or computers or other
        recording media prior to receipt from the disclosing party and was not
        previously acquired by the receiving party from the disclosing party
        under an obligation of confidence; or (ii) to have been developed by or
        for the receiving party at any time independently of the information
        disclosed to it by the disclosing party; or</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span style="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >(c)  which is hereafter disclosed or made available to the receiving
        party from a source other than the disclosing party without breach by
        the receiving party or such source of any obligations of confidentiality
        or non-use towards the disclosing party; or</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >(d)  which is hereafter disclosed by the disclosing party to a third
        party without restriction on disclosure or use, including, without
        limitation, by way of the publication of a patent specification;
        or</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >(e)  which is disclosed by the receiving Party with the prior written
        approval of the disclosing Party.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >1.3  “Effective Date” means the date upon which this agreement shall be
        deemed to have come into effect which shall be the date appearing
        hereinabove;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >1.4  &quot;Intellectual Property&quot; shall mean any company name,
        registered name, trademark, logo, copyright, trade name, or other
        intellectual property right whatsoever of the disclosing Party, whether
        registered or not;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >1.5  ‘Parties’ means (jointly) the Consultant and Platohub and ‘Party’
        shall have a singular and composite meaning.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >1.6  Unless the context indicates a contrary intention:</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >(a)  a reference to the singular shall include the plural and vice
        versa;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >(b)  a reference to any gender shall include the other gender;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >(c)  paragraph headings are inserted for convenience only and shall not
        bear upon the<br />
        interpretation of the Agreement;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 1in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >(d)  a reference to either Party shall be deemed to include a reference
        to its subsidiaries and<br />
        associate companies.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <span style="line-height: 115%; color: #00a9e0"
        >2. HANDLING OF CONFIDENTIAL INFORMATION</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <span style="line-height: 115%"
        >In consideration of the disclosure of Confidential Information, the
        Consultant undertakes in relation to Grupa and its Product Partners
        (“the Disclosing Party”) Confidential Information:</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >2.1  to maintain the same in confidence and to use it only for purposes
        of the negotiations contemplated herein;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >2.2  not to disclose the same except to its directors, officers,
        employees, agents, consultants, legal, tax and investment advisors
        (collectively, the “Representatives”) and except in confidence to such
        of its Representatives who need to know the same for purposes of the
        information disclosures contemplated herein and that such
        Representatives are obliged not to disclose the same;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >2.3  to be responsible for the performance of sub-clauses 2.1, and 2.2
        above on the part of its Representatives to whom the same is disclosed
        and;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >2.4  to apply thereto no lesser security measures and degree of care
        than those which the Consultant applies to its own Confidential
        Information and which the Consultant warrants as providing adequate
        protection of such information from unauthorized disclosure, copying or
        use.</span
      >
    </p>

    <ol style="margin-top: 0in" start="3" type="1">
      <li
        class="MsoNormal"
        style="color: #00a9e0; margin-top: 12pt; margin-bottom: 0in"
      >
        <span style="line-height: 115%"
          >LENGTH OF THIS AGREEMENT<br />
          This Agreement shall be in force from the date herein stated and shall
          expire within 1 year from execution of this Agreement.</span
        >
      </li>

      <li class="MsoNormal" style="color: #00a9e0; margin-bottom: 0in">
        <span style="line-height: 115%"><p>OWNERSHIP AND NO WARRANTY</p></span>
      </li>
    </ol>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >4.1  Each Party reserves all rights in its Confidential Information and
        no rights or obligations other than those expressly cited herein are
        granted or to be implied from this Agreement.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >4.2  The Receiving Party shall not acquire hereunder any right
        whatsoever to any Confidential Information, including without
        limitation, any right or license of any patent, trademark, copyright,
        trade secret, moral right or any other right now or later recognized by
        any law or regulation of any jurisdiction as a result of or in
        connection with any disclosure hereunder. Accordingly, nothing in this
        Agreement is intended or shall be construed as a transfer, grant,
        license, release or waiver of any Intellectual Property Rights in any
        Confidential Information.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >4.3  No disclosure of any Confidential Information by the Parties shall
        constitute any representation or warranty by that Party regarding the
        accuracy of the same or the non-infringement of any patent, trademark,
        copyright or any other intellectual property or proprietary right.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >4.4  Nothing in this Agreement shall be deemed to obligate either Party
        to disclose any Confidential Information to the other, or to accept any
        Confidential Information of the other.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <span style="line-height: 115%; color: #00a9e0"
        >5. RETURN/DESTRUCTION OF CONFIDENTIAL INFORMATION</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >5.1  Each Party shall immediately after the termination of this
        Agreement or at the request of the other Party either destroy or return
        all Confidential Information and any copies thereof in its possession
        and within thirty (30) days of receiving such a request certify in
        writing its compliance.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >5.2  This clause shall not apply to the extent that the Receiving Party
        is obliged to retain any Confidential Information in terms of any
        applicable law, rule or regulation or by any competent judicial,
        governmental, supervisory or regulatory body or in accordance with
        reasonable internal policy.</span
      >
    </p>

    <ol style="margin-top: 0in" start="6" type="1">
      <li class="MsoNormal" style="color: #00a9e0; margin-bottom: 0in">
        <p>
          <span style="line-height: 115%"
            >FURTHER PROTECTION<br />
            In the event that either Party becomes compelled by law or by order
            of any court or tribunal of competent jurisdiction to disclose any
            Confidential Information, the said Party shall give the other Party
            prompt notice but, in any event, not later than five (5) business
            days’ notice prior to such disclosure (where possible) so that the
            other Party may seek a protective order or other appropriate remedy
            and /or waive compliance with the provisions of this
            Agreement.</span
          >
        </p>
      </li>
      <li class="MsoNormal" style="color: #00a9e0; margin-bottom: 0in">
        <p>
          <span style="line-height: 115%"
            >APPLICABLE LAW<br />
            This Agreement shall be exclusively governed and construed in
            accordance with the laws of Delaware and the same law shall
            exclusively govern the merits of any dispute arising out of or in
            connection with this Agreement.</span
          >
        </p>
      </li>
      <li class="MsoNormal" style="color: #00a9e0; margin-bottom: 0in">
        <span style="line-height: 115%">DISPUTE RESOLUTION</span>
      </li>
    </ol>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >8.1  The Parties shall use their best efforts to settle amicably any
        dispute arising from or in connection with this Agreement or the
        interpretation thereof.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >8.2  If the dispute has not been settled pursuant to clause 8.1 above
        within ten (10) business days from when the mediation was instituted,
        any party may elect to institute a legal action </span
      ><span style="font-size: 9pt; line-height: 115%"
        >at the relevant Chancery courts of law in Delaware</span
      ><span style="line-height: 115%">.</span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >8.3  Pending final settlement or determination of a dispute, the
        Parties shall continue to perform their subsisting obligations
        hereunder.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0.5in;
      "
    >
      <span style="line-height: 115%"
        >8.4 Nothing in this Agreement shall prevent or delay a Party seeking
        urgent injunctive or interlocutory relief in a court having
        jurisdiction.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <span style="line-height: 115%; color: #00a9e0">9. NOTICE</span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0.5in;
      "
    >
      <span style="line-height: 115%"
        >9.1 The Parties choose their respective addresses set out in clause 9
        for the purposes of giving of any notice, the service of any process and
        for any purpose arising from this Agreement. For the purpose of this
        Agreement, the Parties’ respective addresses are:</span
      >
    </p>

    <br />
    <br />
    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <b><span style="line-height: 115%">THE CONSULTANT</span></b>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <b
        ><span style="font-size: 12pt; line-height: 115%">{{
          teamDetails.user.name
        }}</span></b
      >
    </p>

    <p class="MsoNormal">
     <span style="line-height: 115%"
          >_________________________________</span
        >
    </p>

    <p class="MsoNormal">&nbsp;</p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 0pt;
        margin-left: 0in;
      "
    >
      <b><span style="line-height: 115%">AND</span></b>
    </p>

    <br />
    <p
      class="MsoNormal"
      style="
        margin-top: 4pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <b><span style="line-height: 1%">PLATOHUB INC dba Grupa </span></b>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: -8pt !important;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <b
        ><span style="line-height: 15%; color: gray"
          >4320 Stevens Creek Blvd, San Jose, CA</span
        ></b
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >9.2  Each of the Parties shall be entitled, by written notice to the
        other, to vary their address for notice from time to time to any other
        address.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >9.3  Any notice is given by one Party to the other (&quot;the
        Addressee&quot;) which:</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >(a)  is delivered by hand during the normal business hours of the
        Addressee at the Addressee's<br />
        address for notice, for the time being, shall be deemed, (unless the
        contrary is proved by the<br />
        Addressee), to have been received by the Addressee at the time of
        delivery;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >(b)  if transmitted by facsimile to the facsimile number forming a part
        of the Addressee's address for notice, or sent by e-mail in Microsoft
        Word/PDF format to the e-mail address of such person previously provided
        to the other Party, be deemed to have been received by the Addressee<br />
        (unless the contrary is proved by the Addressee) one Business Day after
        the date of dispatch.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0.5in;
      "
    >
      <span style="line-height: 115%"
        >9.4 Notwithstanding anything to the contrary contained in this
        Agreement, a written notice or communication received by one of the
        Parties from another will be adequate written notice or communication to
        such Party.</span
      >
    </p>

    <ol style="margin-top: 0in" start="10" type="1">
      <li
        class="MsoNormal"
        style="color: #00a9e0; margin-top: 12pt; margin-bottom: 0in"
      >
        <span style="line-height: 115%"
          >NO COMMITMENT<br />
          Nothing contained in this Agreement shall be construed as implying any
          commitment or agreement by either Party to make any investment in the
          other Party or in any business of the other Party or to enter any
          other business arrangements of any nature whatsoever with respect to
          the other Party.</span
        >
      </li>
      <li class="MsoNormal" style="color: #00a9e0; margin-bottom: 12pt">
        <span style="line-height: 115%"
          >NO PUBLICATION<br />
          Neither Party shall disclose, publicize or advertise in any manner the
          discussions or negotiations contemplated by the Agreement without the
          prior written consent of the other Party, except as may be required by
          law.</span
        >
      </li>
    </ol>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <span style="line-height: 115%; color: #00a9e0">12. GENERAL</span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >1.1  This Agreement and any amendment thereto may be executed in
        counterparts, each of which when executed and delivered shall be deemed
        an original and all of which taken together shall constitute one and the
        same instrument. This Agreement may be delivered by hand or e-mail (as a
        signed PDF).</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >1.2  This Agreement shall not be deemed to create any partnership,
        agency or further relationship between the Parties, the rights, and
        obligations arising from this Agreement being restricted to this
        Agreement.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >1.3  This Agreement constitutes the entire Agreement and understanding
        between the parties in respect of Confidential Information and
        supersedes all previous agreements, understandings, and undertakings in
        such respect.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >1.4  This Agreement is personal to the parties and shall not be
        assigned or otherwise transferred in whole or in part by either
        party.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >1.5  The invalidity or unenforceability of any provision of this
        Agreement shall not affect the validity or enforceability of any other
        provision of this Agreement.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >1.6  No amendment or modification of any term of this Agreement shall
        be valid or binding on the parties unless made in writing and executed
        on behalf of Grupa by a duly authorized representative.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0.5in;
        text-indent: -0.25in;
      "
    >
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="line-height: 115%"
        >1.7  The signatories on behalf of the Parties warrant that they are
        duly authorized hereto.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <b
        ><span style="line-height: 115%"
          >IN WITNESS WHEREOF the parties hereto have caused this Agreement to
          be executed by their duly authorized representatives the day and year
          first above written.</span
        ></b
      >
    </p>
    <br />
    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <span style="line-height: 115%">THE CONSULTANT:</span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <span style="line-height: 115%; color: gray">Expert: </span><br /><b
        ><span style="font-size: 14pt; line-height: 115%">{{
          teamDetails.user.name
        }}</span></b
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <span style="line-height: 115%; color: gray">Address: </span><br />

      <textarea
        rows="5"
        style="width: 80%; border: 1px solid #ccc"
        :value="value"
        @input="onChangeAddress($event.target.value)"
        :placeholder="placeholder"
        ref="input"
      >
      </textarea>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <span style="line-height: 115%; color: gray">Date: </span><br /><b
        style="font-size: 14pt"
        >{{ new Date().toJSON().slice(0, 10).replace(/-/g, "/") }}</b
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 12pt;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0in;
      "
    >
      <span style="line-height: 115%; display: none">Sign:</span>
    </p>
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import { Script } from "vm";
import { mapState } from "vuex";
export default {
  props: {
    handleSignNDA: Function,
    spinner: Boolean,
    project: Object,
    onChangeAddress: Function,
  },
  components: {},

  data: () => ({
    openDrawer: false,
  }),

  created() {},
  methods: {
    toggleNdaDrawer() {
      //this.openDrawer = !this.openDrawer;
    },
    monthName(num) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return monthNames[num];
    },
  },
  computed: {
    ...mapState("devDashboard", ["teamInfo"]),
    teamMembers() {
      return this.teamInfo.team.members;
    },
    teamDetails() {
      return this.teamInfo;
    },
  },
};
</script>

<style scoped>
/* Style Definitions */
p.MsoNormal,
li.MsoNormal,
div.MsoNormal,
span {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 10pt;
  margin-left: 0in;
  line-height: 115%;
}
span {
  font-size: 12pt;
}

p {
  margin-top: 10px !important;
}
.MsoPapDefault {
  margin-bottom: 10pt;
  line-height: 115%;
}
@page WordSection1 {
  size: 8.5in 11in;
  margin: 48.95pt 38.15pt 48.95pt 38.15pt;
}
div.WordSection1 {
  page: WordSection1;
}
/* List Definitions */
ol {
  margin-bottom: 0in;
}
ul {
  margin-bottom: 0in;
}
</style>
