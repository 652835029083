var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "products-wrapper" }, [
        _c(
          "div",
          { staticClass: "product-lists" },
          _vm._l(_vm.projects, function (project, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "single-product-card",
                on: {
                  click: function ($event) {
                    return _vm.openProject(project)
                  },
                },
              },
              [
                _c("div", { staticClass: "product-name" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        project.project_name
                          ? project.project_name
                          : "New Project"
                      ) +
                      "\n        "
                  ),
                ]),
                _c("div", { staticClass: "product-descrition" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.truncate(project.overview, 80)) +
                      "\n        "
                  ),
                ]),
                _c("div", { staticClass: "product-divider" }),
                _c("div", { staticClass: "product-status" }, [
                  _c(
                    "div",
                    {
                      staticClass: "product-stage",
                      class: { "new-product": project.team_acceptance == 0 },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            project.team_acceptance == 0 ? "New" : "Ongoing"
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "vertical-prod-divider" }),
                  _c(
                    "div",
                    {
                      staticClass: "product-stage-name",
                      attrs: {
                        id:
                          "current_iteration_" +
                          (project.team_acceptance == 0 ? null : i),
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            project.team_acceptance == 0
                              ? "Product Request"
                              : "loading... "
                          ) +
                          "\n\n            "
                      ),
                      _c("span", { staticStyle: { display: "none" } }, [
                        _vm._v(_vm._s(_vm.projectIteration(i))),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "product-progress-div" }, [
                  _c("div", { staticClass: "product-progress-bar" }, [
                    _c("div", {
                      staticClass: "moving-progress",
                      style: project.team_acceptance == 0 ? "width:0%;" : "",
                    }),
                  ]),
                  _c("div", { staticClass: "product-percentage" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(project.team_acceptance == 0 ? "0%" : "20%") +
                        "\n          "
                    ),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
      _vm.newProject != null
        ? _c(
            "NewProjectDrawer",
            _vm._b(
              { ref: "drawer" },
              "NewProjectDrawer",
              {
                handleAcceptProject: _vm.handleAcceptProject,
                spinner: _vm.spinner,
                newProject: _vm.newProject,
              },
              false
            )
          )
        : _vm._e(),
      _c(
        "div",
        { attrs: { id: "scrollDetect" } },
        [
          _c(
            "NDADrawer",
            _vm._b(
              { ref: "ndaDrawer", attrs: { project: _vm.newProject } },
              "NDADrawer",
              {
                handleSignNDA: _vm.handleSignNDA,
                spinner: _vm.spinner,
                onChangeAddress: _vm.onChangeAddress,
              },
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }