var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "all-projects" },
      [
        _c(
          "Toolbar",
          _vm._b(
            {},
            "Toolbar",
            {
              tabs: _vm.tabs,
              activeTab: _vm.activeTab,
              switchTabs: _vm.switchTabs,
            },
            false
          )
        ),
        _vm.projects == null
          ? _c("Loader")
          : _c("div", [
              _vm.projects.length > 0
                ? _c("div", { staticClass: "projects-content" }, [
                    _vm.activeTab == "ongoing"
                      ? _c(
                          "div",
                          [
                            _c(
                              "AllProducts",
                              _vm._b(
                                {},
                                "AllProducts",
                                { projects: _vm.projects },
                                false
                              )
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.activeTab == "completed"
                      ? _c("div", { staticClass: "completed" }, [
                          _vm._v("completed"),
                        ])
                      : _vm._e(),
                    _vm.activeTab == "cancelled"
                      ? _c("div", [_vm._v("Cancelled")])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.projects.length == 0
                ? _c("div", { staticClass: "project-empty-state" }, [
                    _c("div", [
                      _c("img", {
                        attrs: { src: "/img/dashboard/folder.svg", alt: "" },
                      }),
                    ]),
                    _c("div", { staticClass: "project-empty-msg" }, [
                      _vm._v(
                        "\n          Your team is not currently building any product on Grupa\n        "
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }