var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-drawer" },
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { right: "", absolute: "", temporary: "" },
          model: {
            value: _vm.openDrawer,
            callback: function ($$v) {
              _vm.openDrawer = $$v
            },
            expression: "openDrawer",
          },
        },
        [
          _c("div", { staticClass: "drawer-wrapper" }, [
            _c("div", { staticClass: "toolbar" }, [
              _c("div", { staticClass: "drawer-title" }, [_vm._v("Legal:")]),
              _c("div", { staticClass: "btn-wrapper" }, [
                _c(
                  "button",
                  {
                    staticClass: "grupa-blue-btn",
                    on: { click: _vm.handleSignNDA },
                  },
                  [
                    _vm._v("\n            Sign NDA\n            "),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.spinner,
                          expression: "spinner",
                        },
                      ],
                      staticClass: "button-spinner",
                      staticStyle: { "margin-left": "20px" },
                      attrs: { src: "/img/lightbox/preloader.gif" },
                    }),
                  ]
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "nda-content" },
              [
                _c(
                  "GrupaNDA",
                  _vm._b(
                    {},
                    "GrupaNDA",
                    { onChangeAddress: _vm.onChangeAddress },
                    false
                  )
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }