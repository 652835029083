<template>
  <div>
    <div class="all-projects">
      
      <Toolbar v-bind="{ tabs, activeTab, switchTabs }" />
      <Loader v-if="projects == null" />
      <div v-else>
        <div class="projects-content" v-if="projects.length > 0">
          <div v-if="activeTab == 'ongoing'">
            <AllProducts v-bind="{ projects }" />
          </div>
          <div v-if="activeTab == 'completed'" class="completed">completed</div>
          <div v-if="activeTab == 'cancelled'">Cancelled</div>
        </div>
        <div class="project-empty-state" v-if="projects.length == 0">
          <div>
            <img src="/img/dashboard/folder.svg" alt="" />
          </div>
          <div class="project-empty-msg">
            Your team is not currently building any product on Grupa
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toolbar from "./toolbar/toolbar";
import AllProducts from "./allProducts/products";
import Loader from "@/components/general/centerLoader";
import { getDev } from "@/api";
import { mapActions, mapState } from "vuex";

export default {
  props: {},
  components: {
    Loader,
    Toolbar,
    AllProducts,
  },

  data: () => ({
    tabs: [
      {
        id: 1,
        name: "Ongoing",
        active: true,
        tab: "ongoing",
      },
      {
        id: 2,
        name: "Completed",
        tab: "completed",
        active: false,
      },
      {
        id: 3,
        name: "Cancelled",
        tab: "cancelled",
        active: false,
      },
    ],
    activeTab: "ongoing",
    projects: null,
  }),

  created() {
    this.getTeamProjects();
  },
  methods: {
    ...mapActions("devDashboard", ["setTeamDetails"]),
    switchTabs(tab) {
      this.activeTab = tab;
    },
    async getTeamProjects() {
      await getDev()
        .then((res) => {
          if (res.data) {
            this.projects = res.data.data.projects;
            this.setTeamDetails(res.data);
          }
        })
        .catch((err) => {
          this.$developConsole(err);
        });
    },
  },
  computed: {},
};
</script>
<style scoped>
.project-empty-state {
  min-height: calc(100vh - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.project-empty-msg {
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: #979da5;
  width: 241px;
  margin-top: 4px;
}
</style>
