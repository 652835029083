<template>
  <div>
    <div class="nav-wrapper">
      <div style="display: flex">
        <div class="project-space">
          <div class="project-name">Products</div>
        </div>
        <div class="nav-items-container">
          <div v-for="tab in tabs" :key="tab.id" @click="switchTabs(tab.tab)">
            <div
              class="nav-item"
              :class="{ 'selected-tab': tab.tab == activeTab }"
            >
              {{ tab.name }}
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px">
        <!-- <img src="/img/dashboard/notification-icon.svg" alt="" /> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tabs: {
      type: Array,
    },
    switchTabs: Function,
    activeTab: String,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.nav-wrapper {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  height: 64px;
  padding: 0px 32px;
  display: flex;
  position: fixed;
  width: calc(100% - 200px);
  top: 0px;
  right: 0px;
  z-index: 700;
  justify-content: space-between;
}
.project-space {
  padding: 16px 0px;
}
.project-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  border-right: 1px solid #e4e5e7;
  padding: 6px 24px 7px 0px;
}
.nav-items-container {
  display: flex;
  margin-left: 24px;
}
.nav-item {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-right: 40px;
  padding: 22px 0px 23px 0px;
  cursor: pointer;
}
.selected-tab {
  color: #1b1e22;
  border-bottom: 1px solid #1b1e22;
}
</style>