<template>
  <div>
    <div class="products-wrapper">
      <div class="product-lists">
        <div
          class="single-product-card"
          v-for="(project, i) in projects"
          :key="i"
          @click="openProject(project)"
        >
          <div class="product-name">
            {{ project.project_name ? project.project_name : "New Project" }}
          </div>
          <div class="product-descrition">
            {{ truncate(project.overview, 80) }}
          </div>
          
          <div class="product-divider"></div>
          <div class="product-status">
            <div
              class="product-stage"
              :class="{ 'new-product': project.team_acceptance == 0 }"
            >
              {{ project.team_acceptance == 0 ? "New" : "Ongoing" }}
            </div>
            <div class="vertical-prod-divider"></div>
            <div class="product-stage-name" :id="`current_iteration_${project.team_acceptance == 0?null:i}`">
              {{
                project.team_acceptance == 0
                  ? "Product Request"
                  : `loading... `
              }}

              <span style="display:none">{{ projectIteration(i) }}</span>
             
            </div>
          </div>
          <div class="product-progress-div">
            <div class="product-progress-bar">
              <div
                class="moving-progress"
                :style="project.team_acceptance == 0 ? 'width:0%;' : ''"
              ></div>
            </div>
            <div class="product-percentage">
              {{ project.team_acceptance == 0 ? "0%" : "20%" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <NewProjectDrawer
      ref="drawer"
      v-bind="{
        handleAcceptProject,
        spinner,
        newProject,
      }"
      v-if="newProject != null"
    />

    <div id="scrollDetect">
      <NDADrawer
        ref="ndaDrawer"
        v-bind="{ handleSignNDA, spinner, onChangeAddress }"
        :project="newProject"
      />
    </div>
  </div>
</template>

<script>
import truncate from "@/mixins/truncate";
import NewProjectDrawer from "@/components/Drawers/projectDrawer";
import NDADrawer from "@/components/Drawers/ndaDrawer";
import { mapActions } from "vuex";
import { acceptProject, teamSignNDA } from "@/api";
import { mapState } from "vuex";

import { getIteration } from "@/api";

export default {
  mixins: [truncate],
  props: {
    projects: Array,
  },
  components: {
    NewProjectDrawer,
    NDADrawer,
  },

  data: () => ({
    spinner: false,
    newProject: null,
    address: "",
  }),

  created() {},
  methods: {
    projectIteration(i) {
      // console.log(this.projects[i].current_iteration);
      // return '00'

      return getIteration(this.projects[i].current_iteration)
        .then((data) => {

         // console.log(data.data)
          $('#current_iteration_'+i).html(data.data)
        })
        .catch((error) => {
          console.log({ error });
          return "Null";
        });

      //return project.current_iteration;
      /* await getIteration(project)
        .then((data) => {
        console.log(JSON.stringify(data))
        }).catch((error) => {
      console.log(error)
    }) */
    },
    ...mapActions("devDashboard", ["updateSelectedProject"]),
    openProject(project) {
     // console.log(project)
      let {
        id,
        project_name,
        workspace_progress_level,
        team_acceptance,
        is_nda,
      } = project;
      this.newProject = project;
      if (
        workspace_progress_level >= 1 &&
        team_acceptance == 1 &&
        is_nda == 1
      ) {
        this.$router.push({ name: "dev_workspace", params: { id } });
        this.updateSelectedProject(project);
      } else if (workspace_progress_level >= 1 && team_acceptance == 0) {
        // New project, not yet accepted
        setTimeout(() => {
          this.$refs.drawer.toggleDrawer();
        }, 300);
      } else if (workspace_progress_level >= 1 && team_acceptance && !is_nda) {
        // New project, accepted,no NDA
        setTimeout(() => {
          this.$refs.ndaDrawer.toggleNdaDrawer();
        }, 300);
      }
    },
    async handleAcceptProject(payload) {
      this.spinner = true;
      console.log(this.newProject)
      let projectId = this.newProject.id;
      await acceptProject(projectId, payload)
        .then((resp) => {
          this.spinner = false;
          this.$refs.drawer.toggleDrawer();
          this.$refs.ndaDrawer.toggleNdaDrawer();
        })
        .catch((error) => {
          this.spinner = false;
        });
    },
    onChangeAddress(text) {
      this.address = text;
      console.log(text);
    },
    async handleSignNDA() {
      if (this.address === "") {
        alert("You need to provide your address to continue");

        /* ß */

        const element = $(`#scrollDetect`);
        element.animate(
          {
            scrollTop: element.prop("scrollHeight"),
          },
          500
        );
      } else {
        this.spinner = true;
        let projectId = this.newProject.id;
        await teamSignNDA(projectId, this.address)
          .then((resp) => {
            this.$refs.ndaDrawer.toggleNdaDrawer();
            this.spinner = false;
            this.$router.push({
              name: "dev_workspace",
              params: { id: projectId },
            });
          })
          .catch((error) => {
            this.spinner = false;
            alert(
              "An unnexpected error occured.\nKindly confirm your internet connection and try again"
            );
          });
      }
    },
  },
  computed: {},
  computed: {
    ...mapState("devDashboard", ["selectedProjectDoc", "selectedProject"]),
    currentIteration() {
      return this.selectedProjectDoc.iterations.find(
        (itr) => itr.id == this.selectedProject.current_iteration
      );
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.products-wrapper {
  margin-top: 67px;
  min-height: calc(100vh - 67px);
  background: #f7f7f8;
  padding: 32px;
}
.product-lists {
  display: flex;
  flex-wrap: wrap;
}
.single-product-card {
  padding: 24px;
  background: #ffffff;
  border-radius: 4px;
  text-align: left;
  width: 282px;
  cursor: pointer;
  margin-right: 16px;
  margin-bottom: 24px;
}
.product-name {
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
  text-transform: capitalize;
}
.product-descrition {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 8px;
  text-transform: capitalize;
  min-height: 34px;
}
.product-divider {
  margin-top: 16px;
  border-top: 1px solid #e4e5e7;
}
.product-status {
  margin-top: 16px;
  display: flex;
}
.product-stage {
  font-size: 14px;
  line-height: 130%;
  color: #54cf94;
  margin-right: 8px;
}
.new-product {
  color: #0781f2;
}
.vertical-prod-divider {
  border-left: 1px solid #e4e5e7;
  height: 18px;
  width: 1px;
}
.product-stage-name {
  margin-left: 8px;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.product-progress-div {
  display: flex;
  margin-top: 16px;
}
.product-progress-bar {
  background: #e4e5e7;
  border-radius: 4px;
  width: 204px;
  height: 4px;
  margin-top: 4px;
}
.moving-progress {
  background: #0781f2;
  height: 100%;
  border-radius: 4px;
  width: 30%;
}
.product-percentage {
  font-size: 12px;
  line-height: 100%;
  color: #1b1e22;
  margin-left: 9px;
}

@media (max-width: 1440px) {
  .single-product-card {
    width: 23%;
  }
}
@media (max-width: 1280px) {
  .single-product-card {
    width: 23%;
  }
}
</style>
