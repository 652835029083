var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "WordSection1" }, [
    _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: { "font-size": "12pt", "line-height": "1.6" },
      },
      [
        _vm._v("\n    " + _vm._s(JSON.stringify(_vm.project)) + "\n    "),
        _c("span", { staticStyle: { "line-height": "115%" } }, [_vm._v("1. ")]),
        _vm._m(0),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v("is made "),
        ]),
        _vm._m(1),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v("this day,\n      "),
          _c("b", [
            _vm._v(
              "\n        " +
                _vm._s(new Date().getDate()) +
                " day of\n        " +
                _vm._s(_vm.monthName(new Date().getMonth())) +
                "\n        , " +
                _vm._s(new Date().getFullYear()) +
                " as consent to use the Grupa platform\n      "
            ),
          ]),
        ]),
        _vm._m(2),
        _vm._m(3),
      ]
    ),
    _vm._m(4),
    _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c("span", {}, [
          _c("b", [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.teamDetails.team.team_name
                    ? _vm.teamDetails.team.team_name
                    : ""
                ) +
                " "
            ),
          ]),
          _vm._v("an expert hereby known as the “"),
          _c("b", [_vm._v("CONSULTANT")]),
          _vm._v("” and collectively\n      known as “Parties”."),
        ]),
      ]
    ),
    _vm._m(5),
    _vm._m(6),
    _vm._m(7),
    _vm._m(8),
    _vm._m(9),
    _vm._m(10),
    _vm._m(11),
    _vm._m(12),
    _vm._m(13),
    _vm._m(14),
    _vm._m(15),
    _vm._m(16),
    _vm._m(17),
    _vm._m(18),
    _vm._m(19),
    _vm._m(20),
    _vm._m(21),
    _vm._m(22),
    _c("br"),
    _vm._m(23),
    _vm._m(24),
    _vm._m(25),
    _vm._m(26),
    _vm._m(27),
    _vm._m(28),
    _vm._m(29),
    _vm._m(30),
    _vm._m(31),
    _vm._m(32),
    _vm._m(33),
    _vm._m(34),
    _vm._m(35),
    _vm._m(36),
    _vm._m(37),
    _vm._m(38),
    _vm._m(39),
    _vm._m(40),
    _vm._m(41),
    _vm._m(42),
    _vm._m(43),
    _vm._m(44),
    _vm._m(45),
    _vm._m(46),
    _vm._m(47),
    _vm._m(48),
    _vm._m(49),
    _vm._m(50),
    _vm._m(51),
    _vm._m(52),
    _vm._m(53),
    _vm._m(54),
    _vm._m(55),
    _vm._m(56),
    _vm._m(57),
    _vm._m(58),
    _c("br"),
    _c("br"),
    _vm._m(59),
    _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c("b", [
          _c(
            "span",
            { staticStyle: { "font-size": "12pt", "line-height": "115%" } },
            [_vm._v(_vm._s(_vm.teamDetails.user.name))]
          ),
        ]),
      ]
    ),
    _vm._m(60),
    _c("p", { staticClass: "MsoNormal" }, [_vm._v(" ")]),
    _vm._m(61),
    _c("br"),
    _vm._m(62),
    _vm._m(63),
    _vm._m(64),
    _vm._m(65),
    _vm._m(66),
    _vm._m(67),
    _vm._m(68),
    _vm._m(69),
    _vm._m(70),
    _vm._m(71),
    _vm._m(72),
    _vm._m(73),
    _vm._m(74),
    _vm._m(75),
    _vm._m(76),
    _vm._m(77),
    _vm._m(78),
    _c("br"),
    _vm._m(79),
    _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c("span", { staticStyle: { "line-height": "115%", color: "gray" } }, [
          _vm._v("Expert: "),
        ]),
        _c("br"),
        _c("b", [
          _c(
            "span",
            { staticStyle: { "font-size": "14pt", "line-height": "115%" } },
            [_vm._v(_vm._s(_vm.teamDetails.user.name))]
          ),
        ]),
      ]
    ),
    _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c("span", { staticStyle: { "line-height": "115%", color: "gray" } }, [
          _vm._v("Address: "),
        ]),
        _c("br"),
        _c("textarea", {
          ref: "input",
          staticStyle: { width: "80%", border: "1px solid #ccc" },
          attrs: { rows: "5", placeholder: _vm.placeholder },
          domProps: { value: _vm.value },
          on: {
            input: function ($event) {
              return _vm.onChangeAddress($event.target.value)
            },
          },
        }),
      ]
    ),
    _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c("span", { staticStyle: { "line-height": "115%", color: "gray" } }, [
          _vm._v("Date: "),
        ]),
        _c("br"),
        _c("b", { staticStyle: { "font-size": "14pt" } }, [
          _vm._v(_vm._s(new Date().toJSON().slice(0, 10).replace(/-/g, "/"))),
        ]),
      ]
    ),
    _vm._m(80),
    _c("br"),
    _c("br"),
    _c("br"),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", { staticStyle: { "line-height": "115%" } }, [
        _vm._v(
          "THIS CONFIDENTIALITY AND NON - DISCLOSURE AGREEMENT (“this\n        Agreement”)\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", { staticStyle: { "line-height": "115%" } }, [_vm._v("on ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "line-height": "115%" } }, [
      _vm._v("between "),
      _c("b", [_vm._v("PLATOHUB INC dba Grupa, ")]),
      _vm._v(
        "a corporation registered under\n      the laws of the State of Delaware and with registered office address at\n      "
      ),
      _c("b", [_vm._v("4320 Stevens Creek Blvd, San Jose, CA")]),
      _vm._v("; "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "line-height": "115%" } }, [
      _vm._v("(hereinafter referred to as “"),
      _c("b", [_vm._v("Grupa")]),
      _vm._v(
        "” which expression shall where\n      the context so admits include its successors and assigns)"
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [_c("span", { staticStyle: { "line-height": "115%" } }, [_vm._v("AND")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v("WHEREAS:"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v("●"),
          _c("span", {}, [_vm._v("    ")]),
        ]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "This Agreement is created for the purpose of preventing the\n      unauthorized disclosure of confidential and proprietary information\n      regarding the development of "
          ),
          _c("b", [_vm._v("the Project")]),
          _vm._v(", hereinafter known as\n      the “Software”."),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0.05in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v("●"),
          _c("span", [_vm._v("    ")]),
        ]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v("Grupa shall on behalf of third parties, its "),
          _c("b", [_vm._v("“Product Partners”")]),
          _vm._v(
            ",\n      on its platform have sole ownership of the Software and the Consultant\n      shall be prohibited from disclosing confidential and proprietary\n      information that is to be released by the Grupa to develop the\n      Software."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0.08in",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v("●"),
          _c("span", [_vm._v("    ")]),
        ]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "The Parties acknowledge that any Confidential Information exchanged\n      between them should be kept confidential and have therefore agreed to\n      enter into this Agreement."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v("NOW IT IS HEREBY AGREED AS FOLLOWS:"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c(
          "span",
          { staticStyle: { "line-height": "115%", color: "#00a9e0" } },
          [_vm._v("1. DEFINITIONS")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", {}, [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "1.1  “Agreement” means this Confidentiality and Non-Disclosure\n      Agreement between the Parties;"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "1.2  “Confidential Information” means all information or data disclosed\n      (whether in writing, orally or by any other means) to one party by the\n      other party or by a third party on behalf of the other"
          ),
          _c("br"),
          _vm._v("\n      party and shall include but not be limited to;"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "1in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "(a)  Information relating to the organizational structure, marketing\n      strategy and valuation of the"
          ),
          _c("br"),
          _vm._v(
            "\n      Parties and any other information relating to the business operations,\n      processes, plans, intentions, product information, know-how, design\n      rights, trade secrets, software, market opportunities, customers and\n      affairs of the Parties;"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "1in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "(b)  technical information to any products or services supplied by the\n      Parties;"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "1in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "(c)  information relating to the officers, directors or employees of\n      the Parties including their"
          ),
          _c("br"),
          _vm._v("\n      terms and conditions of employment or engagement;"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "1in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "(d)  the information which is disclosed orally and identified as\n      confidential at the time of"
          ),
          _c("br"),
          _vm._v("\n      disclosure;"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "1in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "(e)  The existence and content of this Agreement and matters incidental\n      thereto;"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "1in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "(f)  any information ascertainable by the inspection of accounts, and\n      other records;"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "1in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "(g)  financial, legal and any other information provided in due\n      diligence checklists to be provided"
          ),
          _c("br"),
          _vm._v("\n      after execution hereof;"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "1in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "(h)  any information relating to that Party’s business, operations,\n      plans, intentions, market"
          ),
          _c("br"),
          _vm._v("\n      opportunities, customers and business affairs; and"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "1in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v("(i)  any Intellectual Property."),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "But shall exclude any part of such disclosed information or data and\n      information supplied by Grupa Product Owners, Partners, and Stakeholders\n      of the project which:"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c("b", [
          _c("span", { staticStyle: { "line-height": "115%" } }, [
            _vm._v("Alexander Rushing"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
          display: "none",
        },
      },
      [
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v("Document Ref: DBJ4S-LVBWS-KNMN8-QPEHA"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", {}, [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "(a)  is or becomes common or public knowledge in any way without breach\n      of this Agreement by the receiving party; or"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", {}, [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "(b)  the Consultant can show; (i) was in its possession or known to it\n      by being in its use or being recorded in its files or computers or other\n      recording media prior to receipt from the disclosing party and was not\n      previously acquired by the receiving party from the disclosing party\n      under an obligation of confidence; or (ii) to have been developed by or\n      for the receiving party at any time independently of the information\n      disclosed to it by the disclosing party; or"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", {}, [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "(c)  which is hereafter disclosed or made available to the receiving\n      party from a source other than the disclosing party without breach by\n      the receiving party or such source of any obligations of confidentiality\n      or non-use towards the disclosing party; or"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "(d)  which is hereafter disclosed by the disclosing party to a third\n      party without restriction on disclosure or use, including, without\n      limitation, by way of the publication of a patent specification;\n      or"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "(e)  which is disclosed by the receiving Party with the prior written\n      approval of the disclosing Party."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "1.3  “Effective Date” means the date upon which this agreement shall be\n      deemed to have come into effect which shall be the date appearing\n      hereinabove;"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            '1.4  "Intellectual Property" shall mean any company name,\n      registered name, trademark, logo, copyright, trade name, or other\n      intellectual property right whatsoever of the disclosing Party, whether\n      registered or not;'
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "1.5  ‘Parties’ means (jointly) the Consultant and Platohub and ‘Party’\n      shall have a singular and composite meaning."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v("1.6  Unless the context indicates a contrary intention:"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "1in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "(a)  a reference to the singular shall include the plural and vice\n      versa;"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "1in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "(b)  a reference to any gender shall include the other gender;"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "1in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "(c)  paragraph headings are inserted for convenience only and shall not\n      bear upon the"
          ),
          _c("br"),
          _vm._v("\n      interpretation of the Agreement;"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "1in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("        ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "(d)  a reference to either Party shall be deemed to include a reference\n      to its subsidiaries and"
          ),
          _c("br"),
          _vm._v("\n      associate companies."),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c(
          "span",
          { staticStyle: { "line-height": "115%", color: "#00a9e0" } },
          [_vm._v("2. HANDLING OF CONFIDENTIAL INFORMATION")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "In consideration of the disclosure of Confidential Information, the\n      Consultant undertakes in relation to Grupa and its Product Partners\n      (“the Disclosing Party”) Confidential Information:"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "2.1  to maintain the same in confidence and to use it only for purposes\n      of the negotiations contemplated herein;"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "2.2  not to disclose the same except to its directors, officers,\n      employees, agents, consultants, legal, tax and investment advisors\n      (collectively, the “Representatives”) and except in confidence to such\n      of its Representatives who need to know the same for purposes of the\n      information disclosures contemplated herein and that such\n      Representatives are obliged not to disclose the same;"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "2.3  to be responsible for the performance of sub-clauses 2.1, and 2.2\n      above on the part of its Representatives to whom the same is disclosed\n      and;"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "2.4  to apply thereto no lesser security measures and degree of care\n      than those which the Consultant applies to its own Confidential\n      Information and which the Consultant warrants as providing adequate\n      protection of such information from unauthorized disclosure, copying or\n      use."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ol",
      {
        staticStyle: { "margin-top": "0in" },
        attrs: { start: "3", type: "1" },
      },
      [
        _c(
          "li",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              color: "#00a9e0",
              "margin-top": "12pt",
              "margin-bottom": "0in",
            },
          },
          [
            _c("span", { staticStyle: { "line-height": "115%" } }, [
              _vm._v("LENGTH OF THIS AGREEMENT"),
              _c("br"),
              _vm._v(
                "\n        This Agreement shall be in force from the date herein stated and shall\n        expire within 1 year from execution of this Agreement."
              ),
            ]),
          ]
        ),
        _c(
          "li",
          {
            staticClass: "MsoNormal",
            staticStyle: { color: "#00a9e0", "margin-bottom": "0in" },
          },
          [
            _c("span", { staticStyle: { "line-height": "115%" } }, [
              _c("p", [_vm._v("OWNERSHIP AND NO WARRANTY")]),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "4.1  Each Party reserves all rights in its Confidential Information and\n      no rights or obligations other than those expressly cited herein are\n      granted or to be implied from this Agreement."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "4.2  The Receiving Party shall not acquire hereunder any right\n      whatsoever to any Confidential Information, including without\n      limitation, any right or license of any patent, trademark, copyright,\n      trade secret, moral right or any other right now or later recognized by\n      any law or regulation of any jurisdiction as a result of or in\n      connection with any disclosure hereunder. Accordingly, nothing in this\n      Agreement is intended or shall be construed as a transfer, grant,\n      license, release or waiver of any Intellectual Property Rights in any\n      Confidential Information."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "4.3  No disclosure of any Confidential Information by the Parties shall\n      constitute any representation or warranty by that Party regarding the\n      accuracy of the same or the non-infringement of any patent, trademark,\n      copyright or any other intellectual property or proprietary right."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "4.4  Nothing in this Agreement shall be deemed to obligate either Party\n      to disclose any Confidential Information to the other, or to accept any\n      Confidential Information of the other."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c(
          "span",
          { staticStyle: { "line-height": "115%", color: "#00a9e0" } },
          [_vm._v("5. RETURN/DESTRUCTION OF CONFIDENTIAL INFORMATION")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "5.1  Each Party shall immediately after the termination of this\n      Agreement or at the request of the other Party either destroy or return\n      all Confidential Information and any copies thereof in its possession\n      and within thirty (30) days of receiving such a request certify in\n      writing its compliance."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "5.2  This clause shall not apply to the extent that the Receiving Party\n      is obliged to retain any Confidential Information in terms of any\n      applicable law, rule or regulation or by any competent judicial,\n      governmental, supervisory or regulatory body or in accordance with\n      reasonable internal policy."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ol",
      {
        staticStyle: { "margin-top": "0in" },
        attrs: { start: "6", type: "1" },
      },
      [
        _c(
          "li",
          {
            staticClass: "MsoNormal",
            staticStyle: { color: "#00a9e0", "margin-bottom": "0in" },
          },
          [
            _c("p", [
              _c("span", { staticStyle: { "line-height": "115%" } }, [
                _vm._v("FURTHER PROTECTION"),
                _c("br"),
                _vm._v(
                  "\n          In the event that either Party becomes compelled by law or by order\n          of any court or tribunal of competent jurisdiction to disclose any\n          Confidential Information, the said Party shall give the other Party\n          prompt notice but, in any event, not later than five (5) business\n          days’ notice prior to such disclosure (where possible) so that the\n          other Party may seek a protective order or other appropriate remedy\n          and /or waive compliance with the provisions of this\n          Agreement."
                ),
              ]),
            ]),
          ]
        ),
        _c(
          "li",
          {
            staticClass: "MsoNormal",
            staticStyle: { color: "#00a9e0", "margin-bottom": "0in" },
          },
          [
            _c("p", [
              _c("span", { staticStyle: { "line-height": "115%" } }, [
                _vm._v("APPLICABLE LAW"),
                _c("br"),
                _vm._v(
                  "\n          This Agreement shall be exclusively governed and construed in\n          accordance with the laws of Delaware and the same law shall\n          exclusively govern the merits of any dispute arising out of or in\n          connection with this Agreement."
                ),
              ]),
            ]),
          ]
        ),
        _c(
          "li",
          {
            staticClass: "MsoNormal",
            staticStyle: { color: "#00a9e0", "margin-bottom": "0in" },
          },
          [
            _c("span", { staticStyle: { "line-height": "115%" } }, [
              _vm._v("DISPUTE RESOLUTION"),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "8.1  The Parties shall use their best efforts to settle amicably any\n      dispute arising from or in connection with this Agreement or the\n      interpretation thereof."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "8.2  If the dispute has not been settled pursuant to clause 8.1 above\n      within ten (10) business days from when the mediation was instituted,\n      any party may elect to institute a legal action "
          ),
        ]),
        _c(
          "span",
          { staticStyle: { "font-size": "9pt", "line-height": "115%" } },
          [_vm._v("at the relevant Chancery courts of law in Delaware")]
        ),
        _c("span", { staticStyle: { "line-height": "115%" } }, [_vm._v(".")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "8.3  Pending final settlement or determination of a dispute, the\n      Parties shall continue to perform their subsisting obligations\n      hereunder."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0.5in",
        },
      },
      [
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "8.4 Nothing in this Agreement shall prevent or delay a Party seeking\n      urgent injunctive or interlocutory relief in a court having\n      jurisdiction."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c(
          "span",
          { staticStyle: { "line-height": "115%", color: "#00a9e0" } },
          [_vm._v("9. NOTICE")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0.5in",
        },
      },
      [
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "9.1 The Parties choose their respective addresses set out in clause 9\n      for the purposes of giving of any notice, the service of any process and\n      for any purpose arising from this Agreement. For the purpose of this\n      Agreement, the Parties’ respective addresses are:"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c("b", [
          _c("span", { staticStyle: { "line-height": "115%" } }, [
            _vm._v("THE CONSULTANT"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "MsoNormal" }, [
      _c("span", { staticStyle: { "line-height": "115%" } }, [
        _vm._v("_________________________________"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "0pt",
          "margin-left": "0in",
        },
      },
      [
        _c("b", [
          _c("span", { staticStyle: { "line-height": "115%" } }, [
            _vm._v("AND"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "4pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c("b", [
          _c("span", { staticStyle: { "line-height": "1%" } }, [
            _vm._v("PLATOHUB INC dba Grupa "),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "-8pt !important",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c("b", [
          _c("span", { staticStyle: { "line-height": "15%", color: "gray" } }, [
            _vm._v("4320 Stevens Creek Blvd, San Jose, CA"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("      ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "9.2  Each of the Parties shall be entitled, by written notice to the\n      other, to vary their address for notice from time to time to any other\n      address."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            '9.3  Any notice is given by one Party to the other ("the\n      Addressee") which:'
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "(a)  is delivered by hand during the normal business hours of the\n      Addressee at the Addressee's"
          ),
          _c("br"),
          _vm._v(
            "\n      address for notice, for the time being, shall be deemed, (unless the\n      contrary is proved by the"
          ),
          _c("br"),
          _vm._v(
            "\n      Addressee), to have been received by the Addressee at the time of\n      delivery;"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "(b)  if transmitted by facsimile to the facsimile number forming a part\n      of the Addressee's address for notice, or sent by e-mail in Microsoft\n      Word/PDF format to the e-mail address of such person previously provided\n      to the other Party, be deemed to have been received by the Addressee"
          ),
          _c("br"),
          _vm._v(
            "\n      (unless the contrary is proved by the Addressee) one Business Day after\n      the date of dispatch."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0.5in",
        },
      },
      [
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "9.4 Notwithstanding anything to the contrary contained in this\n      Agreement, a written notice or communication received by one of the\n      Parties from another will be adequate written notice or communication to\n      such Party."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ol",
      {
        staticStyle: { "margin-top": "0in" },
        attrs: { start: "10", type: "1" },
      },
      [
        _c(
          "li",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              color: "#00a9e0",
              "margin-top": "12pt",
              "margin-bottom": "0in",
            },
          },
          [
            _c("span", { staticStyle: { "line-height": "115%" } }, [
              _vm._v("NO COMMITMENT"),
              _c("br"),
              _vm._v(
                "\n        Nothing contained in this Agreement shall be construed as implying any\n        commitment or agreement by either Party to make any investment in the\n        other Party or in any business of the other Party or to enter any\n        other business arrangements of any nature whatsoever with respect to\n        the other Party."
              ),
            ]),
          ]
        ),
        _c(
          "li",
          {
            staticClass: "MsoNormal",
            staticStyle: { color: "#00a9e0", "margin-bottom": "12pt" },
          },
          [
            _c("span", { staticStyle: { "line-height": "115%" } }, [
              _vm._v("NO PUBLICATION"),
              _c("br"),
              _vm._v(
                "\n        Neither Party shall disclose, publicize or advertise in any manner the\n        discussions or negotiations contemplated by the Agreement without the\n        prior written consent of the other Party, except as may be required by\n        law."
              ),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c(
          "span",
          { staticStyle: { "line-height": "115%", color: "#00a9e0" } },
          [_vm._v("12. GENERAL")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("      ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "1.1  This Agreement and any amendment thereto may be executed in\n      counterparts, each of which when executed and delivered shall be deemed\n      an original and all of which taken together shall constitute one and the\n      same instrument. This Agreement may be delivered by hand or e-mail (as a\n      signed PDF)."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "1.2  This Agreement shall not be deemed to create any partnership,\n      agency or further relationship between the Parties, the rights, and\n      obligations arising from this Agreement being restricted to this\n      Agreement."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "1.3  This Agreement constitutes the entire Agreement and understanding\n      between the parties in respect of Confidential Information and\n      supersedes all previous agreements, understandings, and undertakings in\n      such respect."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "1.4  This Agreement is personal to the parties and shall not be\n      assigned or otherwise transferred in whole or in part by either\n      party."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "1.5  The invalidity or unenforceability of any provision of this\n      Agreement shall not affect the validity or enforceability of any other\n      provision of this Agreement."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "0in",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "1.6  No amendment or modification of any term of this Agreement shall\n      be valid or binding on the parties unless made in writing and executed\n      on behalf of Grupa by a duly authorized representative."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "0in",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0.5in",
          "text-indent": "-0.25in",
        },
      },
      [
        _c("span", [_vm._v("       ")]),
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v(
            "1.7  The signatories on behalf of the Parties warrant that they are\n      duly authorized hereto."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c("b", [
          _c("span", { staticStyle: { "line-height": "115%" } }, [
            _vm._v(
              "IN WITNESS WHEREOF the parties hereto have caused this Agreement to\n        be executed by their duly authorized representatives the day and year\n        first above written."
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c("span", { staticStyle: { "line-height": "115%" } }, [
          _vm._v("THE CONSULTANT:"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-top": "12pt",
          "margin-right": "0in",
          "margin-bottom": "12pt",
          "margin-left": "0in",
        },
      },
      [
        _c(
          "span",
          { staticStyle: { "line-height": "115%", display: "none" } },
          [_vm._v("Sign:")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }