<template>
  <div class="project-drawer">
    <v-navigation-drawer v-model="openDrawer" right absolute temporary>
      <div class="drawer-wrapper">
        <div class="toolbar">
          <div class="drawer-title">Product Request</div>
          <div class="btn-wrapper">
            <button class="grupa-white-btn">Reject</button>
            <button
              class="grupa-disabled-btn"
              @click="teamAcceptProject"
              style="margin-left: 16px"
              :class="enableBtn ? 'grupa-blue-btn' : null"
              @mouseover="isTooltip = true"
              @mouseleave="isTooltip = false"
            >
              Accept
              <img
                src="/img/lightbox/preloader.gif"
                style="margin-left: 20px"
                class="button-spinner"
                v-show="spinner"
              />
            </button>
          </div>
        </div>
        <div class="drawer-content-top">
          <div class="button-tooltips" v-if="isTooltip && !enableBtn">
            To continue, scroll down and select teammates that would work on
            this product
          </div>
          <div class="div-content">
            <div class="section-label">PRODUCT DETAILS</div>
            <div class="title-icon">
              <div class="project-icons">
                <div>
                  <!-- <img
                    :src="`/img/onboarding/platforms/${replaceUnderscore(
                      newProject.project_type
                    )}.svg`"
                    alt=""
                  /> -->
                </div>
                <div>
                  <!-- <img
                    :src="`/image/clientProjectCategories/${newProject.category.toLowerCase()}.svg`"
                    alt=""
                  /> -->
                </div>
                <div>
                  <img :src="`${imgLink}platform3.svg`" />
                </div>
              </div>
            </div>
          </div>
          <div class="project-name">{{ newProject.project_name }}</div>
          <div class="project-description">
            {{ newProject.overview }}
          </div>
          <div class="project-details">
            <div>
              <div class="div-label">PRODUCT OWNER</div>
              <div class="project-owner">
                <div class="name-short">
                  {{ abbrevateName(newProject.owner.name).toUpperCase() }}
                </div>
                <div style="margin-left: 8px">
                  <div class="info-name">{{ newProject.owner.name }}</div>
                  <div class="info-rate">Unrated</div>
                </div>
              </div>
            </div>

            <!-- <div class="plan-details">
              <div class="div-label">service plan</div>
              <div class="plan-info">
                <div class="plan-icon" style="margin-top: 4px">
                  <img src="/img/onboarding/upstart-icon.svg" />
                </div>
                <div style="margin-left: 4px">
                  <div class="info-name">{{ newProduct.experience }}</div>
                  <div class="info-rate">
                    ${{
                      (
                        newProduct.price / newProduct.iteration_cycle
                      ).toLocaleString()
                    }}/weekly sprint
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <div class="stack-section">
            <div class="pref-stack">
              <div class="div-label">Preferred tech</div>
              <!-- parseToJson -->
              <div class="investor_list">
                <div
                  v-for="(stack, i) in Mystack"
                  :key="i"
                  class="investor_data"
                >
                  {{ stack }}
                </div>
              </div>

              <div class="no-stack" v-if="Mystack === null">No Preference</div>
            </div>
            <div class="similar-site">
              <div class="div-label">similar sites</div>
              <!-- <div class="all-site" style="width: 189px">
                <div
                  v-if="newProject.similar_projects"
                  class="div-site"
                  v-for="(site, i) in parseToJson(newProduct.similar_projects)"
                  :key="i"
                >
                  {{ site }}
                </div>
                <div v-else class="no-stack">No Similar Sites</div>
              </div> -->
            </div>
          </div>
        </div>
        <!-- <div class="drawer-content-bottom">
          <div class="div-label">product Team</div>
          <div class="team-select-info">
            The service plan selected requires a 5-member team. Select 5 members
            from your team that would work on this product. You can always make
            changes later if you need to swap a teammate.
          </div>
          <div class="drag-info">
            Drag each teammate’s avatar into the role they would play on this
            product.
          </div>
          <div class="select-dev-project">
            <div class="team-list">
              <Draggable v-model="teamMembers" group="kanban" tag="div">
                <div
                  class="team-member"
                  v-for="(member, i) in teamMembers"
                  :key="i"
                  v-if="member.name"
                >
                  <div class="profile-image">
                    <v-avatar
                      :size="40"
                      color="grey lighten-4"
                      class="profile_bg"
                      :style="{
                        backgroundImage: `url(${
                          $imageUrl + member.profile_picture || test
                        })`,
                      }"
                    ></v-avatar>
                  </div>
                  <div class="dev-details">
                    <div class="dev-name">
                      {{ shortenName(member.name) }}
                      {{ currentUser.id == member.id ? "(You)" : "" }}
                    </div>
                    <div class="dev-role">
                      {{ member.team_role.split(" ")[0] }}
                    </div>
                  </div>
                </div>
              </Draggable>
            </div> -->
        <!-- <div class="member-selected-wrap">
              <div class="member-div-counter">
              </div>
              <div v-for="(role, index) in teamRole" :key="index">
                <div class="dropbox-role">{{ role.role }}</div>
                <div
                  class="member-dropbox"
                  :class="{ 'no-member': role.developer.length == 0 }"
                >
                  <Draggable
                    v-model="role.developer"
                    @add="drageUpdate($event, index)"
                    group="kanban"
                    tag="div"
                    :id="role.id"
                    v-if="!role.status"
                  >
                    <div
                      v-if="role.developer.length == 0"
                      class="empty-dropbox"
                    >
                      Drop teammate here
                    </div>
                    <div
                      class="selected-team-dev"
                      v-for="(member, i) in role.developer"
                      :key="i"
                    >
                      <div class="profile-image">
                        <v-avatar
                          :size="28"
                          color="grey lighten-4"
                          class="profile_bg"
                          :style="{
                            backgroundImage: `url(${
                              $imageUrl + member.profile_picture || test
                            })`,
                          }"
                        ></v-avatar>
                      </div>
                      <div class="selected-dev-name">
                        {{ member.name }}
                        {{ currentUser.id == member.id ? "(You)" : "" }}
                      </div>
                    </div>
                  </Draggable>
                  <div
                    v-else
                    class="selected-team-dev"
                    v-for="(member, i) in role.developer"
                    :key="i"
                  >
                    <div class="profile-image">
                      <v-avatar
                        :size="28"
                        color="grey lighten-4"
                        class="profile_bg"
                        :style="{
                          backgroundImage: `url(${
                            $imageUrl + member.profile_picture || test
                          })`,
                        }"
                      ></v-avatar>
                    </div>
                    <div class="selected-dev-name">
                      {{ member.name
                      }}{{ currentUser.id == member.id ? "(You)" : "" }}
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
        <!-- </div>
        </div> -->
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import appMixin from "@/mixins/appMixin";
import Draggable from "vuedraggable";
import { mapState, mapActions } from "vuex";
import shortName from "@/mixins/shortName";

export default {
  mixins: [appMixin, shortName],
  props: {
    newProject: Object,
    handleAcceptProject: Function,
    spinner: Boolean,
  },
  components: {
    Draggable,
  },

  data: () => ({
    isTooltip: false,
    openDrawer: false,
    profile: "/img/onboarding/company/profile.jpeg",
    test: "/img/profile.svg",
    teamRole: [
      // {
      //   id: 1,
      //   role: "Technical PM",
      //   dev_role_id: 1,
      //   developer: [],
      //   status: false,
      // },
      // {
      //   id: 2,
      //   role: "Product Designer",
      //   dev_role_id: 2,
      //   developer: [],
      //   status: false,
      // },
      {
        id: 1,
        role: "Software Engineer",
        dev_role_id: 1,
        developer: [],
        status: false,
      },
      {
        id: 2,
        role: "Sofware Engineer",
        dev_role_id: 2,
        developer: [],
        status: false,
      },
      // {
      //   id: 5,
      //   role: "QA Analyst",
      //   dev_role_id: 4,
      //   developer: [],
      //   status: false,
      // },
    ],
    imgLink: "/img/dashboard/client-icons/",
    teamMembers: [],
    Mystack: [],
  }),

  created() {
    this.teamMembers = JSON.parse(JSON.stringify(this.cloneTeamMembers));
    // console.log(JSON.parse(this.newProject.stack));
    this.Mystack = JSON.parse(this.newProject.stack);
    //console.log(JSON.stringify(this.developer))
  },
  methods: {
    developerStacks() {
      let frameWork = [];
      let { language_tools, database_tools, framework_tools, design_tools } =
        this.newProject;
      let tools = [
        language_tools,
        database_tools,
        framework_tools,
        design_tools,
      ];
      console.log(this.newProject);

      console.log(tools);

      if (typeof language_tools != "object") {
        // console.log(tools);
        // console.log(JSON.parse(tools.replace(/\\/g, "")));
      }
      // console.log("lang:: ", language_tools);
      //console.log("typeof:: ", typeof language_tools);
      tools.map((tool) => {
        if (tool && tool !== null) {
          frameWork = [...frameWork, ...tool];
        }
      });
      // console.log({frameWork})

      if (typeof language_tools === "object") {
        // alert("object");
      } else {
        //alert("string");
        // console.log((`${frameWork}`))
      }
      return frameWork;
    },
    toggleDrawer() {
      this.openDrawer = !this.openDrawer;
    },
    drageUpdate(e, row) {
      this.teamRole[row].status = true;
    },
    teamAcceptProject() {
      let projectId = this.newProduct.id;
      let payload = {
        project_id: projectId,
      };
      this.handleAcceptProject(payload);
    },
  },
  computed: {
    ...mapState("devDashboard", ["teamInfo", "newProduct"]),
    developer() {
      // console.log(this.teamInfo.user)
      return this.teamInfo.user;
    },
    teamBio() {
      return this.teamInfo.team;
    },
    cloneTeamMembers() {
      return this.teamInfo.team.members;
    },
    currentUser() {
      return this.teamInfo.user;
    },
    enableBtn() {
      return true;
    },
    memberSelected() {
      let teamArray = this.teamRole;
      return (
        teamArray[0].developer.length +
        teamArray[1].developer.length +
        teamArray[2].developer.length +
        teamArray[3].developer.length +
        teamArray[5].developer.length
      );
    },
  },
};
</script>
<style scoped>
.investor_data {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  width: auto;
  height: 31px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  border-radius: 8px;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.investor_list {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  gap: 8px;
  padding: 20px;
  padding-top: 20px;
  margin-left: -10px;
  min-width: 50px;
  margin-left: 25px;
}
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.toolbar {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 64px;
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  right: 0px;
  z-index: 100;
}
.drawer-wrapper {
  width: 496px;
  /* margin-top: 147px; */
}
.drawer-title {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  padding: 22px 0px;
}
.btn-wrapper {
  margin: 10px 0px 9px 0px;
}
.drawer-content-top {
  padding: 48px 24px;
  text-align: left;
  position: relative;
}
.button-tooltips {
  position: absolute;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  box-shadow: 2px 5px 15px rgba(27, 30, 34, 0.07);
  border-radius: 4px;
  font-size: 10px;
  line-height: 130%;
  color: #1b1e22;
  padding: 8px 16px;
  text-align: left;
  width: 284px;
  top: 4px;
  right: 24px;
}
.drawer-content-bottom {
  border-top: 1px solid #e4e5e7;
  padding: 48px 24px;
  text-align: left;
}
.div-content {
  display: flex;
  justify-content: space-between;
}
.section-label {
  font-size: 14px;
  line-height: 130%;
  text-transform: uppercase;
  color: #979da5;
}
.project-name {
  font-size: 18px;
  line-height: 22px;
  color: #1b1e22;
  margin-top: 16px;
}
.project-description {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  margin-top: 8px;
  width: 448px;
}
.stack-section,
.project-details {
  margin-top: 48px;
  display: flex;
}

.div-label {
  font-size: 14px;
  line-height: 130%;
  text-transform: uppercase;
  color: #979da5;
}
.project-owner {
  display: flex;
  margin-top: 16px;
}
.similar-site,
.plan-details {
  margin-left: 100px;
  display: none;
}
.name-short {
  background: #0781f2;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  line-height: 130%;
  color: #ffffff;
  margin-top: 4px;
}
.info-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  text-transform: capitalize;
}
.info-rate {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 2px;
}
.all-site,
.plan-info {
  display: flex;
  margin-top: 16px;
  flex-wrap: wrap;
}
.no-stack {
  margin-top: 16px;
  font-size: 14px;
  line-height: 130%;
  color: #000000;
}
.div-site {
  background: #f7f7f8;
  border-radius: 30px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 140%;
  color: #53585f;
  margin: 0px 8px 8px 0px;
}
.team-select-info {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 16px;
}
.team-select-info span {
  color: #1fa564;
}
.drag-info {
  font-size: 12px;
  line-height: 140%;
  color: #1fa564;
  margin-top: 24px;
}
.select-dev-project {
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
}
.team-member {
  display: flex;
  margin-bottom: 16px;
  cursor: move;
  /* width: 220px; */
}
.dev-details {
  margin-left: 8px;
}
.dev-name {
  font-size: 12px;
  line-height: 140%;
  color: #1b1e22;
}
.dev-role {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 1px;
}
.profile_bg {
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  background-position: top;
}
.member-selected-wrap {
  background: #f7f7f8;
  border-radius: 4px;
  width: 280px;
  padding: 16px 16px 8px;
}
.member-div-counter {
  text-align: right;
  font-size: 10px;
  line-height: 12px;
  color: #1b1e22;
}
.member-dropbox {
  background: #ffffff;
  border: 1px dashed #c8cbd0;
  box-sizing: border-box;
  width: 248px;
  /* height: 45px; */
  margin: 4px 0px 16px 0px;
  padding: 8px 16px;
}
.dropbox-role {
  font-size: 12px;
  line-height: 140%;
  color: #53585f;
}
.empty-dropbox {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.no-member {
  padding: 14px;
  text-align: center;
}
.selected-team-dev {
  display: flex;
}
.selected-dev-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-left: 8px;
  margin-top: 6px;
}
.project-icons {
  display: flex;
}
.project-icons div {
  margin-left: 32px;
}
</style>

<style>
.project-drawer .v-overlay {
  z-index: 700;
}
.project-drawer .v-overlay--absolute {
  position: fixed;
}
.project-drawer .v-navigation-drawer--open {
  width: 496px !important;
}
.project-drawer .v-navigation-drawer--absolute {
  position: fixed;
  z-index: 1000;
}
</style>
