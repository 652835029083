<template>
  <div class="project-drawer">
    <v-navigation-drawer v-model="openDrawer" right absolute temporary>
      <div class="drawer-wrapper">
        <div class="toolbar">
          <div class="drawer-title">Legal:</div>
          <div class="btn-wrapper">
            <button class="grupa-blue-btn" @click="handleSignNDA">
              Sign NDA
              <img
                src="/img/lightbox/preloader.gif"
                style="margin-left: 20px"
                class="button-spinner"
                v-show="spinner"
              />
            </button>
          </div>
        </div>
        <div class="nda-content"><GrupaNDA v-bind="{ onChangeAddress }" /></div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GrupaNDA from "./GrupaNDA.vue";
export default {
  props: {
    handleSignNDA: Function,
    spinner: Boolean,
    project: Object,
    onChangeAddress: Function,
  },
  components: {
    GrupaNDA,
  },

  data: () => ({
    openDrawer: false,
  }),

  created() {},
  methods: {
    toggleNdaDrawer() {
      this.openDrawer = !this.openDrawer;
    },
    monthName(num) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return monthNames[num];
    },
  },
  computed: {
    ...mapState("devDashboard", ["teamInfo"]),
    teamMembers() {
      return this.teamInfo.team.members;
    },
    teamDetails() {
      return this.teamInfo;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.toolbar {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 64px;
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  right: 0px;
}
.drawer-wrapper {
  width: 596px;
  /* margin-top: 147px; */
}
.drawer-title {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  padding: 22px 0px;
}
.btn-wrapper {
  margin: 10px 0px 9px 0px;
}
.nda-content {
  padding: 28px 24px;
  text-align: left;
}
.nda-title {
  font-size: 18px;
  line-height: 22px;
  color: #1b1e22;
  margin-bottom: 16px;
}
.div-paragraph {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  margin-top: 16px;
}
.div-column {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  margin-top: 8px;
}
</style>

<style>
.project-drawer .v-overlay--absolute {
  position: fixed;
}
.project-drawer .v-navigation-drawer--open {
  width: 596px !important;
}
.project-drawer .v-navigation-drawer--absolute {
  position: fixed;
}
</style>
