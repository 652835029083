var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-drawer" },
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { right: "", absolute: "", temporary: "" },
          model: {
            value: _vm.openDrawer,
            callback: function ($$v) {
              _vm.openDrawer = $$v
            },
            expression: "openDrawer",
          },
        },
        [
          _c("div", { staticClass: "drawer-wrapper" }, [
            _c("div", { staticClass: "toolbar" }, [
              _c("div", { staticClass: "drawer-title" }, [
                _vm._v("Product Request"),
              ]),
              _c("div", { staticClass: "btn-wrapper" }, [
                _c("button", { staticClass: "grupa-white-btn" }, [
                  _vm._v("Reject"),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "grupa-disabled-btn",
                    class: _vm.enableBtn ? "grupa-blue-btn" : null,
                    staticStyle: { "margin-left": "16px" },
                    on: {
                      click: _vm.teamAcceptProject,
                      mouseover: function ($event) {
                        _vm.isTooltip = true
                      },
                      mouseleave: function ($event) {
                        _vm.isTooltip = false
                      },
                    },
                  },
                  [
                    _vm._v("\n            Accept\n            "),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.spinner,
                          expression: "spinner",
                        },
                      ],
                      staticClass: "button-spinner",
                      staticStyle: { "margin-left": "20px" },
                      attrs: { src: "/img/lightbox/preloader.gif" },
                    }),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "drawer-content-top" }, [
              _vm.isTooltip && !_vm.enableBtn
                ? _c("div", { staticClass: "button-tooltips" }, [
                    _vm._v(
                      "\n          To continue, scroll down and select teammates that would work on\n          this product\n        "
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "div-content" }, [
                _c("div", { staticClass: "section-label" }, [
                  _vm._v("PRODUCT DETAILS"),
                ]),
                _c("div", { staticClass: "title-icon" }, [
                  _c("div", { staticClass: "project-icons" }, [
                    _c("div"),
                    _c("div"),
                    _c("div", [
                      _c("img", {
                        attrs: { src: _vm.imgLink + "platform3.svg" },
                      }),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "project-name" }, [
                _vm._v(_vm._s(_vm.newProject.project_name)),
              ]),
              _c("div", { staticClass: "project-description" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.newProject.overview) +
                    "\n        "
                ),
              ]),
              _c("div", { staticClass: "project-details" }, [
                _c("div", [
                  _c("div", { staticClass: "div-label" }, [
                    _vm._v("PRODUCT OWNER"),
                  ]),
                  _c("div", { staticClass: "project-owner" }, [
                    _c("div", { staticClass: "name-short" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm
                              .abbrevateName(_vm.newProject.owner.name)
                              .toUpperCase()
                          ) +
                          "\n              "
                      ),
                    ]),
                    _c("div", { staticStyle: { "margin-left": "8px" } }, [
                      _c("div", { staticClass: "info-name" }, [
                        _vm._v(_vm._s(_vm.newProject.owner.name)),
                      ]),
                      _c("div", { staticClass: "info-rate" }, [
                        _vm._v("Unrated"),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "stack-section" }, [
                _c("div", { staticClass: "pref-stack" }, [
                  _c("div", { staticClass: "div-label" }, [
                    _vm._v("Preferred tech"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "investor_list" },
                    _vm._l(_vm.Mystack, function (stack, i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "investor_data" },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(stack) +
                              "\n              "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm.Mystack === null
                    ? _c("div", { staticClass: "no-stack" }, [
                        _vm._v("No Preference"),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "similar-site" }, [
                  _c("div", { staticClass: "div-label" }, [
                    _vm._v("similar sites"),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }